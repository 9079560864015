<template>
  <div class="practice-box">
    <el-dialog
      :title="this.$t('message.notice')"
      :visible.sync="showLeaveAlert"
      width="30%"
      :close-on-click-modal="false"
      center
    >
      <div>
        <div
          v-html="
            $t('Tests.leaveAlert', {
              counts: this.leaveCountText,
              times: this.leaveSeconds,
              second: this.second
            })
          "
        ></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="showLeaveAlert = false">
          {{ this.$t("message.continue") }}
        </el-button>
      </span>
    </el-dialog>
    <QuestionWrapper
      v-if="questions"
      ref="QuestionWrapper"
      :examineeName="examineeName"
      :testType="practice.name"
      :questionOrder="questionOrder"
      :sectionOrder="sectionOrder"
      :page="page"
      :questions="questions"
      :questionCount="questions.length"
      :examTimeSeconds="testTime"
      :canHighlighter="canHighlighter"
      :canEliminator="canEliminator"
      :canMasking="canMasking"
      @onCanHighlighter="canHighlighter = !canHighlighter"
      @onCanEliminator="onCanEliminator"
      @onCanMasking="canMasking = !canMasking"
      @onBackQuestion="onBackQuestion"
      @onNextQuestion="onNextQuestion"
      @onSubmitSection="onSubmitSection"
      @onBackPage="page--"
      @onNextPage="page++"
      @onOrderQuestion="onOrderQuestion"
      @onExitTest="onExitTest"
      @onClearHighlight="onClearHighlight"
      @onChangeFlag="onChangeFlag"
    >
      <template>
        <template v-if="page === 1">
          <TestDirections1 :testType="practice.name"></TestDirections1>
        </template>
        <template v-if="page === 2">
          <TestDirections2 :testType="practice.name"></TestDirections2>
        </template>
        <template v-if="page === 3">
          <MultipleChoiceWithPassage
            v-if="!loading"
            :id="questionOrder"
            ref="MultipleChoiceWithPassage"
            :testType="practice.name"
            :canHighlighter="canHighlighter"
            :canEliminator="canEliminator"
            :canMasking="canMasking"
            :passageContent="getPassage"
            :questionOrder="questionOrder"
            :content="question.question.content"
            :options="question.question.options"
            :crossOutArr="question.crossOutArr"
            :maskArr="question.maskArr"
            :answer="question.answer"
            @onChangeAnswer="onChangeAnswer"
          ></MultipleChoiceWithPassage>
        </template>
      </template>
    </QuestionWrapper>
  </div>
</template>

<script>
import $ from "jquery";
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import common from "@/mixins/common";
import { user } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import { instant } from "@ivy-way/material";

import QuestionWrapper from "@/views/act/actTest/components/QuestionWrapper";
import MultipleChoiceWithPassage from "@/views/act/actTest/components/MultipleChoiceWithPassage";
import TestDirections1 from "@/views/act/actTest/components/TestDirections1";
import TestDirections2 from "@/views/act/actTest/components/TestDirections2";
import ACTTest from "@/views/act/apis/actTest.js";

export default {
  metaInfo() {
    return {
      title: "Test - " + this.CompanyName
    };
  },

  components: {
    QuestionWrapper,
    MultipleChoiceWithPassage,
    TestDirections1,
    TestDirections2
  },

  mixins: [common],

  props: [],
  data() {
    return {
      loading: false,
      canHighlighter: false,
      canEliminator: false,
      canMasking: false,
      // old
      timeType: 1,
      isReviewPage: false,
      userExamAnswers: null,
      testTime: 0,
      adaptivePractice: null,
      sectionOrder: 1,
      page: 1,
      questionOrder: 1,
      practice: null,
      questions: null,
      answer: "",
      marked: false,
      crossOutActive: false,
      crossOutArr: [],
      maskArr: [],
      userExamId: 0,
      practices: [],
      showQuestion: true,
      examId: 0,
      showLeaveAlert: false,
      spent: 0,
      leaveSeconds: 0,
      leaveTimes: 0,
      questionTimeLine: 0,
      isNew: true,
      other_status: null,
      activities: [],
      annotation: {
        passage: null,
        annotation: []
      },
      subjects: [],
      question: null,
      adaptiveLine: {
        first_correct_count: 0,
        last_correct_count: 0,
        line: {
          first: null
        }
      }
    };
  },
  computed: {
    ...mapState("user", ["lang"]),
    ...mapGetters("user", ["token", "getProfile"]),
    breakTime() {
      if (this.isNew) {
        return {
          section12: {
            hasBreak: false,
            time: this.timeType ? 600 * this.timeType : 600
          },
          section23: {
            hasBreak: true,
            time: this.timeType ? 600 * this.timeType : 600
          },
          section34: {
            hasBreak: false,
            time: this.timeType ? 600 * this.timeType : 600
          }
        };
      } else {
        return {
          section12: {
            hasBreak: true,
            time: this.timeType ? 600 * this.timeType : 600
          },
          section23: {
            hasBreak: true,
            time: this.timeType ? 5 * this.timeType : 5
          },
          section34: {
            hasBreak: true,
            time: this.timeType ? 600 * this.timeType : 600
          }
        };
      }
    },
    instant() {
      return instant;
    },
    showCalculator() {
      let show = false;
      if (this.practiceType === "singlePractice") {
        if (this.practice.subject_id === 4) {
          show = true;
        }
      } else {
        show = true;
      }
      return show;
    },
    showReference() {
      let show = false;
      if (this.practiceType === "singlePractice") {
        if (this.practice.subject_id === 3 || this.practice.subject_id === 4) {
          show = true;
        }
      } else {
        show = true;
      }
      return show;
    },
    pastUserExamId() {
      return this.$route.query.user_exam_id;
    },
    examineeName() {
      const { first_name, last_name } = this.$store.getters["user/getProfile"];
      return user.displayName(first_name, last_name);
    },
    getPassage() {
      return this.question.question.passage?.content;
    },
    leaveCountText() {
      let times = "time";
      if (this.lang.value === "EN-US") {
        if (this.leaveTimes <= 1) {
          times = this.leaveTimes + " " + "time";
        } else {
          times = this.leaveTimes + " " + "times";
        }
      } else {
        times = this.leaveTimes + " " + "次";
      }
      return times;
    },
    second() {
      let second = "second";
      if (this.lang.value === "EN-US") {
        if (this.leaveSeconds <= 1) {
          second = "second";
        } else {
          second = "seconds";
        }
      } else {
        second = "秒";
      }
      return second;
    },
    testId() {
      return this.$route.params.id;
    },
    practiceType() {
      return this.$route.params.practiceType;
    },
    hasTime() {
      return (
        !(
          this.practice &&
          this.practice.marks &&
          this.practice.marks[1] &&
          this.practice.marks[1].name.en === "drill"
        ) && this.timeType > 0
      );
    }
  },
  watch: {},

  async mounted() {
    await this.startTest();
    this.testTime = this.setExamTimeSeconds();
    this.leaveAlert();
  },
  destroyed() {
    window.removeEventListener("beforeunload", e =>
      this.beforeUnloadHandler(e)
    );
    window.removeEventListener("unload", e => this.unloadHandler(e));
  },

  methods: {
    onCanEliminator() {
      this.canEliminator = !this.canEliminator;
    },
    leaveAlert() {
      setInterval(() => {
        this.spent++;
      }, 1000);
      let vm = this;
      let leaveStart = 0;
      window.onblur = function() {
        if (vm.page === 3) {
          vm.showLeaveAlert = false;
          leaveStart = vm.spent;
          vm.leaveTimes++;
          document.title =
            "Warning: Do not leave the browser in the middle of the test";
          let nowTime = vm.$refs.QuestionWrapper.remainExamTimeSeconds;
          let type = "Leave";
          vm.question.metadata.leaveActivities.push({
            nowTime,
            type
          });
        }
      };
      window.onfocus = function() {
        if (vm.page === 3) {
          vm.leaveSeconds += vm.spent - leaveStart;
          document.title = "Test - " + vm.CompanyName;
          if (vm.leaveSeconds > 0) {
            vm.showLeaveAlert = true;
            let nowTime = vm.$refs.QuestionWrapper.remainExamTimeSeconds;
            let type = "Back";
            vm.question.metadata.leaveActivities.push({
              nowTime,
              type
            });
          }
        }
      };
    },
    beforeUnloadHandler(e) {
      e.returnValue = "Are you sure you want to leave this page?";
      return " ";
    },
    unloadHandler(e) {
      e.returnValue = "Are you sure you want to leave this page?";
      return " ";
    },
    async setNextSection() {
      await this.setSection();
      this.questionOrder = 1;
      this.setQuestion();
    },
    setSection() {
      this.practice = this.practices[this.sectionOrder - 1];
      this.testTime = 0;
      this.testTime = this.setExamTimeSeconds();
      this.questions = this.practice.exam_questions;
      this.page = 1;
    },
    setQuestion() {
      if (this.questions) {
        let question = this.questions[this.questionOrder - 1];
        this.question = question;
        this.question.metadata.activities.push([
          {
            testTime: this.$refs.QuestionWrapper
              ? this.$refs.QuestionWrapper.remainExamTimeSeconds
              : this.setExamTimeSeconds(),
            content: "Started question",
            type: "START"
          }
        ]);
      }
    },
    onChangeFlag() {
      if (this.questions[this.questionOrder - 1].isMarked) {
        this.questions[this.questionOrder - 1].isMarked = false;
        this.$set(
          this.questions,
          this.questionOrder - 1,
          this.questions[this.questionOrder - 1]
        );
      } else {
        this.questions[this.questionOrder - 1].isMarked = true;
        this.$set(
          this.questions,
          this.questionOrder - 1,
          this.questions[this.questionOrder - 1]
        );
      }
    },
    setExamTimeSeconds() {
      let testTime = 60 * 60;
      if (this.practice.name == "English") {
        testTime = 45 * 60;
      } else if (this.practice.name == "Math") {
        testTime = 60 * 60;
      } else if (
        this.practice.name == "Reading" ||
        this.practice.name == "Science"
      ) {
        testTime = 35 * 60;
      } else {
        testTime = 60 * 60;
      }
      return Math.round(testTime);
    },
    changePhone() {
      this.$router.replace({
        name: "SATMobileTest",
        query: this.$route.query,
        params: this.$route.params
      });
    },
    async startTest() {
      const res = await ACTTest.getUserExam(this.$route.params.userExamId);
      await this.setUserTest(res);
    },
    setUserTest(res) {
      res.exam.exam_sections.forEach(practice => {
        practice.exam_questions.forEach(question => {
          question.crossOutArr = [];
          question.maskArr = [];
        });
      });
      this.practices = res.exam.exam_sections;
      //获取之前的考试
      this.userExamAnswers = res.user_exam.user_exam_answers;
      this.setAnswers();

      this.sectionOrder =
        res.user_exam.metadata && res.user_exam.metadata.sectionOrder
          ? res.user_exam.metadata.sectionOrder
          : this.sectionOrder;
      this.practice = this.practices[this.sectionOrder - 1];
      this.questions = this.practice.exam_questions;
      this.setSection();
      this.questionOrder =
        res.user_exam.metadata && res.user_exam.metadata.questionOrder
          ? res.user_exam.metadata.questionOrder
          : this.questionOrder;
      // if (this.questionOrder > 1) {
      //   this.page = 3;
      // }
      this.setQuestion();
    },
    onExitTest(isEnd, seconds) {},

    //清除高亮
    onClearHighlight() {
      this.$refs.MultipleChoiceWithPassage.reSetPassage();
    },
    async onNextQuestion() {
      if (this.questionOrder !== this.questions.length) {
        await this.updateAnswers();
        await this.questionOrder++;
        this.setQuestion();
      }
    },
    async onBackQuestion() {
      if (this.questionOrder !== 0) {
        await this.updateAnswers();
        await this.questionOrder--;
        this.setQuestion();
      }
    },
    //结束当前单元
    async onSubmitSection(
      seconds,
      remainInterval,
      questionSecondsSpentInterval
    ) {
      if (this.sectionOrder < this.practices.length) {
        clearInterval(remainInterval);
        clearInterval(questionSecondsSpentInterval);
        await this.updateAnswers();
        this.sectionOrder++;
        await this.setNextSection();
      } else {
        await this.updateAnswers();
        await ACTTest.submitExam(this.$route.params.userExamId);
        this.$router.replace({
          name: "ACTResultPage",
          params: { userExamId: this.$route.params.userExamId }
        });
      }
    },
    async onEndSection() {},
    //选择选项
    onChangeAnswer(value) {
      this.answer = value;
      // this.question.question.answer = value;
      this.question.answer = value;
      this.questions[this.questionOrder - 1].answer = value;
      this.questions[this.questionOrder - 1].question.answer = false;
      this.$set(
        this.questions,
        this.questionOrder - 1,
        this.questions[this.questionOrder - 1]
      );

      //更新答题的细节
      this.question.metadata.activities[
        this.question.metadata.activities.length - 1
      ].push({
        testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
        content: `Answer ${value}`,
        answer: value,
        type: "ANSWER"
      });
    },
    // 更新答案
    async updateAnswers() {
      // this.loading = true;
      //更新答题的细节
      if (
        this.question.metadata.activities &&
        this.question.metadata.activities.length > 0
      ) {
        let content = this.question.answer
          ? `Submitted answer ${this.question.answer}`
          : "Skipped";
        this.question.metadata.activities[
          this.question.metadata.activities.length - 1
        ].push({
          testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
          content: content,
          answer: this.question.answer ? this.question.answer : "",
          type: "SUBMIT"
        });
      }
      //去后端更新答案
      await ACTTest.updatePracticeAnswers(this.$route.params.userExamId, {
        exam_question_id: this.question.id,
        answer: this.question.answer ? [this.question.answer] : [],
        metadata: this.question.metadata
      });
      // this.loading = false;
      await this.updateExamMetaData();
    },
    async updateExamMetaData() {
      await ACTTest.updateExamMetaData(this.$route.params.userExamId, {
        metadata: {
          questionOrder: this.questionOrder,
          sectionOrder: this.sectionOrder
        }
      });
    },
    onOrderQuestion(order) {
      this.updateAnswers();
      this.questionOrder = order;
      this.setQuestion();
    },
    //把历史答案引入题目中
    setAnswers() {
      this.practices.forEach(practice => {
        practice.exam_questions.forEach(question => {
          let answer = null;
          let metadata = { activities: [], leaveActivities: [] };
          if (this.userExamAnswers && this.userExamAnswers.length > 0) {
            this.userExamAnswers.forEach(userAnswer => {
              if (question.id === userAnswer.exam_question_id) {
                answer =
                  userAnswer.answer && userAnswer.answer[0]
                    ? userAnswer.answer[0]
                    : "";
                metadata = userAnswer.metadata
                  ? userAnswer.metadata
                  : { activities: [], leaveActivities: [] };
              }
            });
          }
          question["answer"] = answer;
          question["metadata"] = metadata;
        });
      });
    }
  }
};
</script>

<style scoped>
.practice-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  font-weight: 500;
  /* user-select: none; */
}
.test-paper {
  padding-top: 0;
  max-width: 2000px;
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}
/* ::v-deep p {
  margin: 0;
} */
.change-phone {
  width: 100%;
  background: var(--themeColor);
  position: fixed;
  z-index: 99999;
  bottom: 0;
  padding: 15px;
  color: white;
}
.change-phone h6 {
  margin-bottom: 15px;
  color: white;
}
.change-phone-cover {
  position: fixed;
  z-index: 100;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.8;
}
@media screen and (min-width: 768px) {
  .change-phone,
  .change-phone-cover {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .change-phone,
  .change-phone-cover {
    display: block;
  }
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
</style>
